import React, { FC, useContext } from "react";
import { AppContext } from "../context/AppContext";

const ToastContainer: FC = () => {
    const { toasts, removeToast } = useContext(AppContext);

    const handleRemoveToast = (id: string) => () => {
        removeToast(id);
    };

    if (!toasts.length) return null;

    return (
        <div className="toast-container">
            {toasts.map(t => (
                <div key={t.id} className={`alert alert-${t.type} alert-dismissible`}>
                    {t.type === "danger" && (
                        <button onClick={handleRemoveToast(t.id)} className="close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    )}
                    <h3 className="alert-heading">{t.type === "danger" ? "Error" : "Info"}</h3>
                    <p className="mb-0">{t.message}</p>
                </div>
            ))}
        </div>
    );
};

export default ToastContainer;
