import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./Header";
import Footer from "./Footer";
import ToastContainer from "../ToastContainer";
import Loader from "./Loader";
import { AppContext } from "../../context/AppContext";
import SEO from "./SEO";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "animate.css/animate.min.css";
import "../../scss/style.scss";

type PropsType = {
    isLoading?: boolean;
    children: ReactNode;
}

const Layout: FC<PropsType> = (props: PropsType) => {
    const { scrollOffset } = useContext(AppContext);

    const [isStickyNavbar, setIsStickyNavbar] = useState(false);
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        setIsStickyNavbar(scrollOffset > 50);
        setShowScrollToTop(scrollOffset > window.outerHeight);
    }, [scrollOffset]);

    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <>
            {props.isLoading && <Loader />}

            <SEO />

            <Header siteTitle={data.site.siteMetadata.title} stickyNavbar={isStickyNavbar} />
            <main className="main-area">
                <div className="main-content">
                    {props.children}
                </div>
                <Footer showScrollToTop={showScrollToTop} />
            </main>

            <ToastContainer />
        </>
    );
};

export default Layout;
