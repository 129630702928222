import React, { useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import MenuLink from "./MenuLink";
import { trimLastSlash } from "../../helpers/utils";
import LogoWhite from "../../assets/logo-white-arka.svg";
import LogoBlack from "../../assets/logo-black-arka.svg";
import classNames from "classnames";

type PropsType = {
    siteTitle: string;
    stickyNavbar: boolean;
}

const Header = (props: PropsType) => {
    const [navbarCollapsed, setNavbarCollapsed] = useState(false);

    const location = useLocation();
    const currentPage = trimLastSlash(location.pathname);
    // const isAuthenticated = useSelector((state: RootState) => (state.auth.isAuthenticated));

    const navbarCssClass = `navbar navbar-expand-lg fixed-top shadow-sm ${props.stickyNavbar ? "navbar-light bg-white sticky" : "navbar-dark bg-dark"}`;

    return (
        <header className="header-area">
            <nav className={navbarCssClass}>
                <div className="container">
                    <Link to="/" className="navbar-brand" title={props.siteTitle}>
                        <span className={classNames({ "d-none": props.stickyNavbar })} >
                            <LogoWhite />
                        </span>
                        <span className={classNames({ "d-none": !props.stickyNavbar })} >
                            <LogoBlack />
                        </span>
                    </Link>
                    <button className="navbar-toggler" onClick={() => setNavbarCollapsed(navbarCollapsed => !navbarCollapsed)} aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={`collapse navbar-collapse${navbarCollapsed ? " show" : ""}`}>
                        <ul className="navbar-nav ml-auto">
                            {/* <li className="current-page-item"><Link to="/" title="Acasă">Acasă</Link></li> */}
                            <MenuLink currentPage={currentPage} to="/adorare" title="Adorare" />
                            <MenuLink currentPage={currentPage} to="/rascumparare" title="Răscumpărare" />
                            <MenuLink currentPage={currentPage} to="/krestere" title="Kreștere" />
                            <MenuLink currentPage={currentPage} to="/afectiune" title="Afecțiune &amp; Ajutorare" />
                            <MenuLink currentPage={currentPage} to="/contact" title="Contact" />
                            {/* {!isAuthenticated && (
                                <MenuLink currentPage={currentPage} to="/login" title="Contul meu" />
                            )}
                            {isAuthenticated && (
                                <>
                                    <MenuLink currentPage={currentPage} to="/profile" title="Profil" />
                                    <MenuLink currentPage={currentPage} to="/logout" title="Logout" />
                                </>
                            )} */}
                        </ul>
                    </div>
                </div>
            </nav>
        </header >
    );
};

export default Header;
