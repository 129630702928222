import React, { FC } from "react";
import SvgFacebook from "@icon/icofont/icons/facebook.svg";
import SvgSwooshUp from "@icon/icofont/icons/swoosh-up.svg";
import { Link } from "gatsby";

type PropsType = {
    showScrollToTop: boolean;
}

const Footer: FC<PropsType> = (props: PropsType) => {
    const onScrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    };

    return (
        <>
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="social-links-area">
                                <a href="https://www.facebook.com/arka.constanta" title="Facebook - Arka" target="_blank" rel="noopener noreferrer">
                                    <i><SvgFacebook /></i>
                                    Arka
                                </a>
                                <a href="https://www.facebook.com/arkakids.constanta" title="Facebook - Arka Kids" target="_blank" rel="noopener noreferrer">
                                    <i><SvgFacebook /></i>
                                    Arka Kids
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="text-container">
                        <p>2010 - {new Date().getFullYear()}. Toate drepturile rezervate.</p>
                        <p>Acest site foloseşte cookies. Continuarea navigării presupune că ești de acord cu <Link to="/politica-cookies" title="Politica utilizare cookies">politica de utilizare a cookie-urilor</Link>.</p>
                    </div>
                </div>
            </footer>

            <button id="scrollToTop" className={`btn btn-theme ${!props.showScrollToTop ? "d-none" : ""}`} title="Scroll to top" onClick={onScrollToTop}>
                <SvgSwooshUp />
            </button>
        </>
    );
};

export default Footer;
