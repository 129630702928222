import React from "react";
import { Link } from "gatsby";

type PropsType = {
    currentPage: string;
    to: string;
    title: string;
}

const MenuLink = (props: PropsType) => (
    <li className={`nav-item ${props.currentPage === props.to ? "active" : ""}`}><Link to={props.to} title={props.title} className="nav-link">{props.title}</Link></li>
);

export default MenuLink;
