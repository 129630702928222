import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type PropsType = {
    title?: string;
    description?: string;
    lang?: string;
    meta?: HTMLMetaElement[];
}

const SEO: FC<PropsType> = (props: PropsType) => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    defaultPageTitle
                    description
                    keywords
                    config {
                        recaptchaSiteKey
                    }
                }
            }
        }
    `);

    const title = props.title || site.siteMetadata.defaultPageTitle;
    const metaDescription = props.description || site.siteMetadata.description;
    const lang = props.lang || "ro";
    const meta = props.meta || [];

    const recaptchaScriptUrl = `https://www.recaptcha.net/recaptcha/api.js?render=${site.siteMetadata.config.recaptchaSiteKey}`;

    return (
        <Helmet
            defer={false}
            htmlAttributes={{ lang }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: "description",
                    content: metaDescription,
                },
                {
                    name: "keywords",
                    content: site.siteMetadata.keywords,
                },
                {
                    property: "og:title",
                    content: title,
                },
                {
                    property: "og:description",
                    content: metaDescription,
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    name: "twitter:card",
                    content: "summary",
                },
                {
                    name: "twitter:title",
                    content: title,
                },
                {
                    name: "twitter:description",
                    content: metaDescription,
                },
            ].concat(meta)}
        >
            <script src={recaptchaScriptUrl}></script>
        </Helmet>
    );
};

export default SEO;
